/**
 * WARNING: DO NOT CHANGE THIS FILE UNLESS YOU KNOW HOW THE JENKINS BUILDS WORKS
 * WARNING: DO NOT CHANGE THIS FILE UNLESS YOU KNOW HOW THE JENKINS BUILDS WORKS
 * WARNING: DO NOT CHANGE THIS FILE UNLESS YOU KNOW HOW THE JENKINS BUILDS WORKS
 * !!! NOTE: If you want to add more variables, please add them to env.derived.js instead !!!
 */
window.__env = {};
/**
 * BUILD REPLACEMENTS : These values / variables will be replaced during build / deploy time by jenkins
 */
/**
 * A Domain indicates if we are running on DEV/QA/STAGE, etc environments...
 */
window.__env.domain = 'QA3';
/**
 * An api host is an API hostname which gets replaced with appropriate hostname per environment during jenkins builds
 * you can use this apiHost variable to compose other URLS.. in env.derived.js file
 */
window.__env.apiHost = 'https://int.api.ellielabs.com';
/**
 * App Dynamics Key & Beacon urls
 */
window.__env.appDKey = 'AD-AAB-AAW-UVC';
window.__env.appDBeaconUrlHttp = 'http://pdx-col.eum-appdynamics.com';
window.__env.appDBeaconUrlHttps = 'https://pdx-col.eum-appdynamics.com';
/**
 * Session Timeout...
 */
window.__env.uiSessionTimeout = 15;
window.__env.uiSessionTimeoutDialog = 5;
/**
 * Provider Ids
 */
window.__env.uspsAddressValidationProviderId = 'cc450c60-f54a-472c-a321-df32aa334ac9';
/**
 * Ping ID Two Factor URLs...
 */
window.__env.idpHost = 'https://int.idp.ellielabs.com';
window.__env.docViewerUrl = 'https://int.viewer.rd.elliemae.io';
window.__env.requestEconsentUrl = 'https://int.eclose.rd.elliemae.io/econsent/index.html';
window.__env.disclosureMicroAppUrl = 'https://int.eclose.rd.elliemae.io/disclosures/index.html';
window.__env.esignPackagePipelineUrl = 'https://int.package-pipeline.rd.elliemae.io';
window.__env.efolderMicroappUrl = 'https://int.efolder.rd.elliemae.io/efolder/index.html';
window.__env.plancodeMicroappUrl = 'https://int.docflows.rd.elliemae.io/docflows/plancode';
window.__env.retrieveMicroappUrl = 'https://int.docflows.rd.elliemae.io/docflows/retrieve';
window.__env.epcHAURL = 'http://encompass-dev.elliemae.io';
window.__env.bidTapeMicroappUrl = 'https://int.bidtape.rd.elliemae.io';
window.__env.diagnosticsServiceUrl = 'https://int.api.ellielabs.com/diagnostics/v2/logging';
window.__env.serverUrl = 'http://encompass-dev.elliemae.io';
